.defaultClass{
  color: black;
  font-weight: 400;
}
.d1{
  font-family: 'Tactic Trial';
  font-size: 4.5rem;
  line-height: 6rem;
  letter-spacing: -0.04em;

} 
.d2{
  font-family: 'Tactic Trial';
  font-size: 4rem;
  line-height: 5rem;
  letter-spacing: -0.04em;

} 
.d3{
  font-family: 'Tactic Trial';
  font-size: 3rem;
  line-height: 3.5;
  letter-spacing: -0.04em;

} 
.h1{
  font-family: 'Tactic Trial';
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
} 
.h1Alt{
  font-family: 'Maax Mono';
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
} 
.h2{
  font-family: 'Tactic Trial';
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
} 
.h2Alt{
  font-family: 'Maax Mono';
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
}
.h3{
  font-family: 'Tactic Trial';
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
} 
.h3Alt{
  font-family: 'Maax Mono';
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
} 
.h4{
  font-family: 'Tactic Trial';
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: -0.02em;
} 
.h4Alt{
  font-family: 'Maax Mono';
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: -0.02em;
} 
.h5{
  font-family: 'Tactic Trial';
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;

}
.s1{
  font-family: 'Maax Mono';
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
}
.s2{
  font-family: 'Maax Mono';
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.01em;
}
.s2Bold{
  font-family: 'Maax Mono Bold';
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.01em;
}
.body1{
  font-family: 'Maax Mono';
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
}
.body1Bold{
  font-family: 'Maax Mono Bold';
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
} 
.body2{
  font-family: 'Maax Mono';
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.01em;
}
.body2Bold{
  font-family: 'Maax Mono Bold';
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.01em;
} 
.body1XL{
  font-family: 'Maax Mono';
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.01em;
} 
.body1XLBold{
  font-family: 'Maax Mono Bold';
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.01em;
} 
.btn{
  font-family: 'Maax Mono';
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;

} 
.btnM{
  font-family: 'Maax Mono';
  font-size: 1.125rem;
  letter-spacing: -0.01em;

} 
.btnL{
  font-family: 'Maax Mono';
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.01em;

} 
.caption{
  font-family: 'Maax Mono';
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: -0.01em;
} 
.captionXS{
  font-family: 'Maax Mono';
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: -0.01em;
}
.captionBold{
  font-family: 'Maax Mono Bold';
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: -0.01em;

} 
.captionXSBold{
  font-family: 'Maax Mono Bold';
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: -0.01em;

}