@mixin mobileSmall{
  @media screen and (max-width: 320px){
    @content;
  }
}
@mixin mobileMedium{
  @media screen and (min-width: 375px){
    @content;
  }
}
@mixin mobileLarge{
  @media screen and (min-width: 425px){
    @content;
  }
}
@mixin tablet{
  @media screen and (min-width: 768px){
    @content;
  }
}
@mixin notDesktop{
  @media screen and (max-width: 1023px){
    @content;
  }
}
@mixin desktopSmall{
  @media screen and (min-width: 1024px){
    @content;
  }
}
@mixin desktopMedium{
  @media screen and (min-width: 1200px){
    @content;
  }
}
@mixin desktopLarge{
  @media screen and (min-width: 1440px){
    @content;
  }
}

