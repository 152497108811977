@use "./styles/colours.scss" as colours;
@use "./styles/mixins/breakpoints.scss" as breakpoints;
@font-face {
  font-family: 'Tactic Trial';
  src: local('Tactic Trial'), url("assets/fonts/TacticTrial-Regular.otf") format('opentype');
}
@font-face {
  font-family: 'Maax Mono';
  src: local('Maax Mono'), url("assets/fonts/MaaxMono-Regular.otf") format('opentype');
}
@font-face {
  font-family: 'Maax Mono Bold';
  src: local('Maax Mono Bold'), url("assets/fonts/MaaxMono-Bold.otf") format('opentype');
}
body {
  margin: 0;
  font-family: 'Maax Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,h2,h3,h4,h5,p{
  margin: 0;
}
input,button{
  font-family: 'Maax Mono';
}
code {
  font-family: 'Maax Mono';
}
:root{
  --max-height: calc(var(--vh, 1vh) * 100);
  --filter-track: #f2f2f2;
}
::-webkit-scrollbar{
  width: 0.25rem;
  height: 0.25rem;
}
::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb{
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
}
.cc-window{
  font-family: 'Maax Mono';
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(255, 255, 255, 0.2) !important;
    backdrop-filter: blur(80px);
  }
}
.cc-btn{
 font-family: 'Maax Mono';
 font-weight: 300;
 font-size: 18px;
}
.cc-allow{
  color: #000000 !important;
  border-radius: 20px !important;
  transition: all 0.3s ease-in-out;
  padding: 9px 16px;
}
.cc-allow:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.cc-allow:active{
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
}
body{
  #mobile{
    width: calc(100vw - 4rem);
    height: 100vh;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
    font-size: 1.5rem;
    color: colours.$gray-75;
    img{
      width: 6.25rem;
      margin-bottom: 2rem;
    }
    @include breakpoints.desktopSmall{
      display: none !important;
    }
  }
 #root{
  @include breakpoints.notDesktop{
    display: none;
  }
  @include breakpoints.desktopSmall{
    display: unset;
  }
 }
 .cc-revoke{
  @include breakpoints.notDesktop{
    display: none;
  }
  @include breakpoints.desktopSmall{
    display: unset;
  }
 }
}